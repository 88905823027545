window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.a || (__tnt.subscription.a = []);
window.__tnt.subscription.access.manifest || (window.__tnt.subscription.access.manifest = {
    denied: {
        promise: null,
        resolve: function(){ }
    }, 
    granted: {
        promise: null,
        resolve: function(){ }
    }
});

/**
 * Access Granted - Display unencrypted content
 */
window.__tnt.subscription.a.push(function(oResp){
    var content = document.getElementById('asset-content'),
        encryptedContent = null,
        subscriberOnly = null;

    if(content){ 
        encryptedContent = content.querySelector(".subscriber-only.encrypted-content");
        subscriberOnly = content.querySelectorAll(".subscriber-only,.subscriber-hide");

        if(subscriberOnly && !encryptedContent){
            subscriberOnly.forEach(function(el){ el.style.display = "" });
        }
    }
});

/**
 * Access Granted - Display gift wall success
 */
window.__tnt.subscription.a.push(function(oResp) {
    const urlParams = new URLSearchParams(document.location.search);
    if (urlParams.has('gift')) {
        if (!__tnt.user.services && urlParams.has('gift_token')) {
            $('#tnt-gift-wall-modal').modal({
                show: true,
                backdrop: 'static',
                keyboard: false
            });
        }
    }
});

/**
 * Access Granted - Display notifications
 */
window.__tnt.subscription.a.push(function(oResp){ 

    if(oResp.access_meter){

// USER ACCESS THROUGH METERING
        userStatus.accessMethod = "Meter: "+oResp.access_meter.id;

// notification area and message
        var count = oResp.access_meter.count,
            messages = oResp.access_meter.messages,
            messageText = "",
            messageId = "",
            foundMessage = false,
            i = 0,
            eedition = document.getElementById('eedition-wrapper');

        while(i<messages.length && !foundMessage){
            if(messages[i].show_at == count){
                messageText = messages[i].message;
                messageId = messages[i].id;
                foundMessage = true;
            }

            i++;
        }

        if(foundMessage && !eedition){
// METERED ACCESS MESSAGE
            var assetContent = document.getElementById('asset-content'), 
                notificationArea = document.getElementById('notification-area'),
                notificationEl = document.createElement('div'),
                notificationClose = document.createElement('button'),
                notificationMessage = document.createElement('p');

                notificationEl.classList.add('meter', 'notification', 'alert', 'alert-dismissable', 'alert-info');
                notificationEl.setAttribute('data-message',messageId);
                notificationEl.setAttribute('data-count',count);

                notificationClose.setAttribute('type','button');
                notificationClose.setAttribute('data-dismiss','alert');
                notificationClose.setAttribute('aria-hidden',true);
                notificationClose.classList.add('close');
                notificationClose.innerHTML = "&times";

                notificationMessage.innerHTML = messageText

                notificationEl.appendChild(notificationClose);
                notificationEl.appendChild(notificationMessage);

            if(!notificationArea && assetContent){ 
                notificationArea = document.createElement('div');
                notificationArea.id = "notification-area";

                notificationArea.appendChild(notificationEl);

                assetContent.querySelector('.asset-body').appendChild(notificationArea);
            }else{
                notificationArea.appendChild(notificationEl);
            }
        }
    }

});

/**
 * Access Granted - Log first free view
 */
window.__tnt.subscription.a.push(function(oResp){
    if(oResp.access_meter && oResp.access_meter.count == 1){
        __tnt.trackEvent({'category':'subscription','action':'free view','label':'access first view','value':oResp.access_meter.count});
    }
});

/**
 * Access Granted - Hide in page message if it exists
 */
window.__tnt.subscription.a.push(function(oResp){
    var articleBody = document.getElementById('article-body'),
        offersInPage = null;
        
    if(articleBody){ offersInPage = articleBody.querySelector('.access-offers-in-page') }
    if(offersInPage){ offersInPage.hidden = true }
});

__tnt.subscription.access.manifest.granted.resolve();
